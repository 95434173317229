import axios from "axios"; // Ensure axios is imported
import { GEMINI_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";

const useAssistant = (selectedOrganization) => {
  const { accessToken } = AuthState();

  const meetingPoints = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/meeting_points`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const generateProjectReport = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/summary_project`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const userProgress = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/user_summary`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const bestContributor = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/who_contributed_most`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const summarizeCurrentSprint = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/summary_active_sprint`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const projectHealth = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/project_health`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const teamBandwidth = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/team_bandwidth`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const riskBlockers = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/risk_blockers`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  const teamBurnrate = async (fields) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };
      const response = await axios.post(
        `${baseUrl}/team_burnrate`,
        fields,
        config
      );
      if (response?.data?.status === "success") {
        return response?.data?.content;
      } else {
        return `Couldn't generate a response. `;
      }
    } catch (err) {
      console.error(err);
      return `Couldn't generate a response. `;
    }
  };

  return {
    meetingPoints,
    generateProjectReport,
    userProgress,
    bestContributor,
    summarizeCurrentSprint,
    projectHealth,
    teamBandwidth,
    riskBlockers,
    teamBurnrate,
  };
};

export default useAssistant;
