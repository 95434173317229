import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import { FaApple, FaFacebook, FaMicrosoft } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { AuthState } from "../../Context/AuthProvider";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-beta.png";
import { UserState } from "../../Context/UserProvider";

export default function SigninForm({ setPage }) {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const {
    auth,
    allowUserFetch,
    preventUserFetch,
    handle3rdPartyAuth,
    checkUserExists,
    reloadUser,
    deleteFirebaseUser,
    saveUserData,
  } = AuthState();
  const toast = useToast();
  const {
    isOpen: isForgotPasswordModalOpen,
    onOpen: onForgotPasswordModalOpen,
    onClose: onForgotPasswordModalClose,
  } = useDisclosure();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    const errors = {};
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.password) errors.password = "Password is required.";
    if (!isValidEmail()) errors.email = "Invalid email.";
    return errors;
  };

  const isValidEmail = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(formData.email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    try {
      allowUserFetch();
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
    } catch (error) {
      preventUserFetch();
      toast({
        title: `Error occurred`,
        description: `Kindly recheck your entered email and password or Sign In using google.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handle3rdPartySignIn = async (provider) => {
    try {
      // allowUserFetch();
      preventUserFetch();
      const user = await handle3rdPartyAuth(provider);
      const email = user.email;

      try {
        let userExists = false;
        let response;
        try {
          response = await checkUserExists(email, user.uid, user.accessToken);
          userExists = response;
          console.log(response);
        } catch (error) {
          console.log(error.response);
          userExists = error.response.data.exists;
        } finally {
          console.log(userExists);
        }

        if (userExists === false) {
          const userData = {
            firebaseUserId: user.uid,
            firstName: user.displayName?.split(" ")[0] || "",
            lastName: user.displayName?.split(" ").slice(1).join(" ") || "",
            email: user.email,
          };

          try {
            await saveUserData(userData);

            // navigate to create organization page
            navigate("/organizations");
            allowUserFetch();
            reloadUser();
            setLoading(false);
          } catch (error) {
            await deleteFirebaseUser(user);

            toast({
              title: `No such acount exists. You need to sign up first.`,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        } else if (userExists === undefined) {
          throw new Error("User not found");
        } else if (userExists === true) {
          allowUserFetch();
          reloadUser();
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        preventUserFetch();
        await signOut(auth);
        toast({
          title: `Couldn't sign in with ${provider} account. Please try again.`,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        navigate("/auth", { state: "signup" });
      }
    } catch (error) {
      preventUserFetch();
      toast({
        title: `Couldn't sign in with ${provider} account. Please try again.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      minHeight="100vh"
      bg="gray.50"
      width="100vw"
      minWidth="100vw"
      background={`radial-gradient(circle, rgba(255,255,255,0.2), rgba(255,255,255,0.2) 50%),
        linear-gradient(135deg,#0C9ECB, #96D0A2, #4D7CBE,#c1e1bf, #4853a4)`}
      overflowY="auto"
    >
      {/* <div style={{ overflowY:"scroll", height: "100vh" }}> */}

      {isForgotPasswordModalOpen && (
        <ForgotPasswordModal
          isOpen={isForgotPasswordModalOpen}
          onClose={onForgotPasswordModalClose}
        />
      )}
      <VStack
        width="full"
        maxWidth="500px"
        margin="auto"
        spacing={8}
        p={8}
        borderRadius="xl"
        boxShadow="2xl"
        bg="rgba(255, 255, 255, 0.8)"
        backdropFilter="blur(10px)"
        // minHeight="100vh"
        justifyContent="center"
      >
        <Image
          src={logo}
          alt="pmbot"
          height="100px"
          onClick={() => navigate("/")}
          cursor="pointer"
        />
        <VStack spacing={4} width="full" align="stretch">
          <Heading fontSize="3xl" textAlign="center">
            Welcome Back
          </Heading>
          <Text textAlign="center">
            New here?{" "}
            <Text
              as="span"
              fontWeight={600}
              color="blue.500"
              cursor="pointer"
              onClick={() => setPage("signup")}
            >
              Create an account
            </Text>
          </Text>
        </VStack>
        <VStack spacing={4} width="full">
          <FormControl id="login-email" isRequired isInvalid={errors.email}>
            <FormLabel>Email</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="gray.300" />
              </InputLeftElement>
              <Input
                name="email"
                placeholder="Enter Your Email Address"
                onChange={handleChange}
                value={formData.email}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSubmit(e);
                }}
                bg="white"
              />
            </InputGroup>
            {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
          </FormControl>
          <FormControl
            id="login-password"
            isRequired
            isInvalid={errors.password}
          >
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <LockIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Your Password"
                name="password"
                onChange={handleChange}
                value={formData.password}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSubmit(e);
                }}
                bg="white"
              />
              <InputRightElement>
                <Button
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)}
                  variant="ghost"
                >
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.password && (
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            )}
          </FormControl>
        </VStack>
        <HStack justify="space-between" width="full">
          <Checkbox
            size="sm"
            colorScheme="blue"
            isChecked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          >
            Remember me
          </Checkbox>
          <Text
            fontSize="sm"
            color="blue.500"
            cursor="pointer"
            onClick={onForgotPasswordModalOpen}
          >
            Forgot Password?
          </Text>
        </HStack>
        <Button
          isLoading={loading}
          colorScheme="blue"
          width="full"
          onClick={handleSubmit}
          size="lg"
          borderRadius="full"
        >
          Sign In
        </Button>
        <VStack width="full" spacing={4}>
          <HStack width="full">
            <Divider />
            <Text fontSize="sm" whiteSpace="nowrap" color="gray.500">
              Or continue with
            </Text>
            <Divider />
          </HStack>
          <HStack spacing={4}>
            <Tooltip label="Sign In with Google">
              <Button
                variant="outline"
                borderRadius="full"
                size="lg"
                bgColor={"white"}
                onClick={() => handle3rdPartySignIn("google")}
              >
                <FcGoogle />
              </Button>
            </Tooltip>
            <Tooltip label="Sign In with Microsoft">
              <Button
                variant="outline"
                borderRadius="full"
                size="lg"
                bgColor={"white"}
                onClick={() => handle3rdPartySignIn("microsoft")}
              >
                <FaMicrosoft />
              </Button>
            </Tooltip>
          </HStack>
        </VStack>
      </VStack>
      {/* </div> */}
    </Box>
  );
}  