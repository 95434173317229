import {
  Box,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackDivider,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { UserState } from "../../../Context/UserProvider";

const ProfilePic = ({ children }) => {
  const imageRef = useRef(null);
  const [file, setFile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { updateUserProfilePicture } = UserState();

  const updatePicture = async () => {
    if (!file) return;

    const ALLOWED_EXTENSIONS = ["png", "jpg", "jpeg", "gif"];
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
      toast({
        title: "Invalid file type.",
        description: `Allowed file types are: ${ALLOWED_EXTENSIONS.join(
          ", "
        )}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: "File is too large.",
        description: `Maximum file size is ${
          MAX_FILE_SIZE / (1024 * 1024)
        } MB.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await updateUserProfilePicture(formData);
      if (!response) {
        throw new Error("Could not update profile picture.");
      }
    } catch (error) {
      toast({
        title: "Error updating profile picture.",
        description: error.response ? error.response.data.error : error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setFile(null);
      onClose();
    }
  };

  const removePicture = async () => {};

  const updatePictureClicked = (e) => {
    imageRef.current.click();
    onClose();
  };

  const removePictureClicked = (e) => {
    onClose();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (file) {
      updatePicture();
    }
  }, [file]);

  return (
    <>
      <Input
        ref={imageRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        display="none"
        id="profile-pic-upload"
      />
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          setFile(null);
          onClose();
        }}
      >
        <PopoverTrigger>{children}</PopoverTrigger>
        <Portal>
          <PopoverContent width={"100%"}>
            <VStack
              width={"100%"}
              padding={2}
              divider={<StackDivider borderColor="gray.200" />}
              fontSize={"12px"}
            >
              <Box
                _hover={{ cursor: "pointer" }}
                onClick={updatePictureClicked}
              >
                Update Picture
              </Box>
              <Box
                _hover={{ cursor: "pointer" }}
                onClick={removePictureClicked}
              >
                Remove Picture
              </Box>
            </VStack>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
};

export default ProfilePic;
