import { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { TASK_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";

const useMyTasks = (selectedOrganization) => {
  const { accessToken } = AuthState();
  const [myTasks, setMyTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(0);

  const reload = () => {
    setReloadFlag((prevValue) => prevValue + 1);
  };

  useEffect(() => {
    if (!selectedOrganization) {
      setMyTasks([]);
      return;
    }

    const getMyTasksData = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "x-org-id": selectedOrganization.id,
          },
        };

        const response = await axios.get(
          `${baseUrl}/get_assigned_tasks?orgId=${selectedOrganization.id}`,
          config
        );

        const myTasksData = response?.data?.data;

        if (myTasksData && Array.isArray(myTasksData)) {
          // console.log(myTasksData);
          setMyTasks(myTasksData);
        } else {
          throw new Error("MyTasks Data not found");
        }
      } catch (err) {
        // console.log(err);
        setError("Error while fetching my tasks");
      } finally {
        setLoading(false);
      }
    };

    getMyTasksData();
  }, [selectedOrganization, reloadFlag]);

  return { myTasks, setMyTasks, loading, error, setError, reload };
};

export default useMyTasks;
