// import React, { useContext, useEffect, useState } from "react";
// import { Doughnut } from "react-chartjs-2";
// import { 
//   Chart as ChartJS, 
//   ArcElement, 
//   Tooltip, 
//   Legend 
// } from "chart.js";
// ChartJS.register(ArcElement, Tooltip, Legend);

// import { CheckCircleIcon } from "@chakra-ui/icons";
// import {
//   Box,
//   Avatar,
//   Heading,
//   Text,
//   HStack,
//   VStack,
//   Divider,
//   Tag,
//   Grid,
//   GridItem,
//   Flex,
//   Badge,
//   Stat,
//   StatLabel,
//   StatNumber,
//   StatGroup,
//   Progress,
//   Center,
//   Spinner
// } from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
// import { LayoutState } from "../Context/LayoutProvider";
// import { UserState } from "../Context/UserProvider";


// const MyProfile = () => {

//   const [profileData, setProfileData] = useState(null);
//   const [skills, setSkills] = useState({});
//   const navigate = useNavigate()
//   // const { userData, isLoading, error } = useUser();

//   // if (isLoading) {
//   //   return (
//   //     <Center height="100vh" width="100vw">
//   //       <Spinner size="xl" />
//   //     </Center>
//   //   );
//   // }

//   // if (error) {
//   //   return (
//   //     <Center height="100vh">
//   //       <Text color="red.500">{error}</Text>
//   //     </Center>
//   //   );
//   // }

//   // if (userData) {
//   //   return (
//   //     <Center height="100vh">
//   //       <Text color="red.500">Failed to load user data. Please try again.</Text>
//   //     </Center>
//   //   );
//   // }

//   // orgId => selectedOrganization.id
//   const {fetchMemberData,selectedOrganization} = UserState();
//   // const {
//   //   firstName,
//   //   lastName,
//   //   email,
//   //   imageUrl,
//   //   weeklyHours,
//   //   currentBandwidth,
//   //   currentTaskHours,
//   //   tasksCompleted,
//   //   tasksFailed,
//   //   tasksSuccessful,
//   //   totalTasks,
//   //   skills,
//   // } = {
//   //   currentBandwidth: 27.0,
//   //   currentTaskHours: 39.5,
//   //   email: "saeed.ahmed@lumiorc.com",
//   //   firstName: "Saeed",
//   //   lastName: "Ahmed",
//   //   imageUrl:
//   //     "https://storage.googleapis.com/lumiorc-pmbot-images/users/images/66d80419cf871d807e5a2ec3/a-guy-sitting-on-his-desk-wearing-a-black-hoodie-working-on-his-laptop.png",
//   //   tasksCompleted: 61,
//   //   tasksFailed: 0,
//   //   tasksSuccessful: 0,
//   //   totalTasks: 70,
//   //   weeklyHours: 40.0,
//   //   skills: {
//   //     html: "3",
//   //   },
//   // };

//   const fetchData = async ()=>{
//     try{
//       const data = await fetchMemberData("5FreaoSJgAVggx8vzmTkWh7UGzf2",selectedOrganization?.id);
//       setProfileData(data);
//       console.log("data",data)
//       setSkills(data?.skills || {});
//     } catch(e){
//       console.log('error from my profile fetch data',e)
//     }
    
    
//   }
  
//   useEffect(()=>{
//     fetchData()
//   },[])



//   // Destructure profileData safely
//   const {
//     firstName = "",
//     lastName = "",
//     email = "",
//     imageUrl = "",
//     weeklyHours = 0,
//     currentBandwidth = 0,
//     currentTaskHours = 0,
//     tasksCompleted = 0,
//     tasksFailed = 0,
//     tasksSuccessful = 0,
//     totalTasks = 0
//   } = profileData || {};
  

//   // userData
//   const {screenWidth} = LayoutState()
//   let padLeft = 0;
//   if (screenWidth > 780) {
//     padLeft = 220;
//   }  
  

//   const chartData = profileData ? {
//     labels: ["Completed", "Failed", "Pending"],
//     datasets: [
//       {
//         data: [
//           tasksCompleted, 
//           tasksFailed, 
//           totalTasks - tasksCompleted - tasksFailed
//         ],
//         backgroundColor: ["#4CAF50", "#F44336", "#FFC107"],
//         hoverBackgroundColor: ["#66BB6A", "#EF5350", "#FFD54F"],
//       },
//     ],
//   } : null;
  
//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//       },
//     },
//   };
  
  
//   return (
    
//       <Box
//         w={'100vw'}
//         bg="white"
//         mt={0}
//         borderRadius="md"
//         shadow="md"
//         p={6}
//         h={'100vh'}
//         pl={padLeft}
//         minH="100%"
//         minW={'100%'}
//         display="flex"
//         flexDirection="column"
//         overflow="auto"
//         justifyContent="space-between"
//       >
//         <Grid templateColumns="1fr" gap={6}>
//           {/* Header Section */}
//           <GridItem>
//             <Flex
//               direction={screenWidth<420?"row":"column"}
//               align={screenWidth > 420 ? "flex-start" : "center"}
//               gap={{ base: 4, md: 6 }}
//               mb={6}
//               ml={4}
//             >
//               <GridItem>
//                 <Avatar size={{ base: "xl", md: "2xl" }} src={imageUrl} name={`${firstName} ${lastName}`} />
//               </GridItem>
//               <GridItem>
//                 <VStack align={{ base: "flex-start"}} spacing={2} width="100%">
//                   <Heading size="lg">{`${firstName} ${lastName}`}</Heading>
//                   <Text fontSize="md" color="gray.600">
//                     {email}
//                   </Text>
//                   <HStack>
//                     <Badge colorScheme="green">{`Bandwidth: ${currentBandwidth}`}</Badge>
//                     <Badge colorScheme="blue">{`Weekly Hours: ${weeklyHours}`}</Badge>
//                   </HStack>
//                 </VStack>
//               </GridItem>
//             </Flex>
//           </GridItem>

//           <GridItem>
//             <Divider />
//           </GridItem>
// {/* // orgid -> 67018b5b8e2f22b21025855a // userid -> 5FreaoSJgAVggx8vzmTkWh7UGzf2 */}
//           {/* Stats Section */}
//           <GridItem>
//             <StatGroup flexWrap="wrap" gap={4} ml={4}>
//               <Grid templateColumns={{ base: "1fr 1fr", md: "repeat(4, 1fr)" }} gap={4} width="100%">
//                 <Stat>
//                   <StatLabel>Total Tasks</StatLabel>
//                   <StatNumber>{totalTasks}</StatNumber>
//                 </Stat>
//                 <Stat>
//                   {/* <CheckCircleIcon color="green.400" /> */}
//                   <StatLabel>Tasks Completed</StatLabel>
//                   <StatNumber>{tasksCompleted}</StatNumber>
//                 </Stat>
                
//                 <Stat>
//                   <StatLabel>Successful Tasks</StatLabel>
//                   <StatNumber>{tasksSuccessful}</StatNumber>
//                 </Stat>
//               </Grid>
//             </StatGroup>
//           </GridItem>

//           <GridItem>
//             <Divider />
//           </GridItem>



          
//            {/* Chart Section */}
//           <GridItem>
//             {chartData && <Doughnut data={chartData} options={chartOptions} />}
//           </GridItem> 
  
         

//           {/* Progress Bar Section */}
//           <GridItem ml={4}>
//             <Heading size="md" mb={2}>
//               Success Rate
//             </Heading>
//             <Progress
//               value={(tasksCompleted / totalTasks) * 100}
//               colorScheme="green"
//               size="lg"
//               borderRadius="md"
//               border="2px solid"
//               borderColor="gray.300"
//               boxShadow="md"
//             />
//             <Text mt={2} fontSize="sm" color="gray.600">{`Tasks Successfully Performed: ${tasksCompleted} / ${totalTasks}`}</Text>
//           </GridItem>


//           <GridItem>
//             <Divider />
//           </GridItem>


//           {/* Skills Section */}
//           <GridItem ml={4}>
//             <Heading size="md" mb={2}>
//               Skills
//             </Heading>
//             <HStack wrap="wrap" spacing={4}>
//               {Object.entries(skills).map(([skill, level]) => (
//                 <Tag key={skill} colorScheme="teal">{`${skill.toUpperCase()} (Level: ${level})`}</Tag>
//               ))}
//             </HStack>
//           </GridItem>

//           {/* Metrics Section */}
//           <GridItem ml={4}>
//             <Heading size="md" mb={2}>
//               Metrics
//             </Heading>
//             <HStack wrap="wrap" spacing={4}>
//               <Badge colorScheme="purple">{`Task Hours: ${currentTaskHours}`}</Badge>
//             </HStack>
//           </GridItem>
//         </Grid>
//       </Box>
  
//   );
// };

// export default MyProfile;


import React, { useContext, useEffect, useState } from "react";
import { Doughnut,Pie} from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Avatar,
  Heading,
  Text,
  HStack,
  VStack,
  Divider,
  Tag,
  Grid,
  GridItem,
  Flex,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Progress,
  Center,
  Spinner
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { LayoutState } from "../Context/LayoutProvider";
import { UserState } from "../Context/UserProvider";

// Register ChartJS components after imports
ChartJS.register(ArcElement, Tooltip, Legend);

const MyProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const [skills, setSkills] = useState({});
  const [error,setError] = useState(false)
  const navigate = useNavigate();

 

  // orgId => selectedOrganization.id
  const { fetchMemberData, selectedOrganization } = UserState();
  

  const fetchData = async () => {
    try {
      setError(false)
      const data = await fetchMemberData("5FreaoSJgAVggx8vzmTkWh7UGzf2", selectedOrganization?.id);
      setProfileData(data);
      console.log("data", data);
      setSkills(data?.skills || {});
    } catch (e) {
      console.log("error from my profile fetch data", e);
      setError(true)
      
    }

  };

  useEffect(() => {
    fetchData();
  }, []);

  // Destructure profileData safely
  const {
    firstName = "",
    lastName = "",
    email = "",
    imageUrl = "",
    weeklyHours = 0,
    currentBandwidth = 0,
    currentTaskHours = 0,
    tasksCompleted = 0,
    tasksFailed = 0,
    tasksSuccessful = 0,
    totalTasks = 0
  } = profileData || {};

  // userData
  const { screenWidth } = LayoutState();
  let padLeft = 0;
  if (screenWidth > 780) {
    padLeft = 220;
  }

  const chartData = profileData
    ? {
        labels: ["Completed", "Failed", "Pending"],
        datasets: [
          {
            data: [tasksCompleted, tasksFailed, totalTasks - tasksCompleted - tasksFailed],
            backgroundColor: ["#4CAF50", "#F44336", "#FFC107"],
            hoverBackgroundColor: ["#66BB6A", "#EF5350", "#FFD54F"],
          },
        ],
      }
    : null;

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  if (error) {
    return (
      <Flex
        w="100vw"
        h="100vh"
        align="center"
        justify="center"
       
        textColor="red.500"
      >
        <Box textAlign="center" fontSize="lg" overflow={'hidden'}>
          Failed to load user data. Please try again.
        </Box>
      </Flex>
    );
  }
  

  return (
    <Box
      w={"100vw"}
      bg="white"
      mt={0}
      borderRadius="md"
      shadow="md"
      p={6}
      h={"100vh"}
      pl={padLeft}
      minH="100%"
      minW={"100%"}
      display="flex"
      flexDirection="column"
      overflow="auto"
      justifyContent="space-between"
    >
      <Grid templateColumns="1fr" gap={6}>
        {/* Header Section */}
        <GridItem>
          <Flex
            direction={screenWidth < 420 ? "row" : "column"}
            align={screenWidth > 420 ? "flex-start" : "center"}
            gap={{ base: 4, md: 6 }}
            mb={6}
            ml={4}
          >
            <GridItem>
              <Avatar size={{ base: "xl", md: "2xl" }} src={imageUrl} name={`${firstName} ${lastName}`} />
            </GridItem>
            <GridItem>
              <VStack align={{ base: "flex-start" }} spacing={2} width="100%">
                <Heading size="lg">{`${firstName} ${lastName}`}</Heading>
                <Text fontSize="md" color="gray.600">
                  {email}
                </Text>
                <HStack>
                  <Badge colorScheme="green">{`Bandwidth: ${currentBandwidth}`}</Badge>
                  <Badge colorScheme="blue">{`Weekly Hours: ${weeklyHours}`}</Badge>
                </HStack>
              </VStack>
            </GridItem>
          </Flex>
        </GridItem>

        <GridItem>
          <Divider />
        </GridItem>

        {/* Stats Section */}
        <GridItem>
          <StatGroup flexWrap="wrap" gap={4} ml={4}>
            <Grid templateColumns={{ base: "1fr 1fr", md: "repeat(4, 1fr)" }} gap={4} width="100%">
              <Stat>
                <StatLabel>Total Tasks</StatLabel>
                <StatNumber>{totalTasks}</StatNumber>
              </Stat>
              <Stat>
                {/* <CheckCircleIcon color="green.400" /> */}
                <StatLabel>Tasks Completed</StatLabel>
                <StatNumber>{tasksCompleted}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Successful Tasks</StatLabel>
                <StatNumber>{tasksSuccessful}</StatNumber>
              </Stat>
            </Grid>
          </StatGroup>
        </GridItem>

        <GridItem>
          <Divider />
        </GridItem>

        {/* Chart Section */}
        <GridItem h={200} w={200}>{chartData && <Pie data={chartData} options={chartOptions} />}</GridItem>

        {/* Progress Bar Section */}
        <GridItem ml={4}>
          <Heading size="md" mb={2}>
            Success Rate
          </Heading>
          <Progress
            value={(tasksCompleted / totalTasks) * 100}
            colorScheme="green"
            size="lg"
            borderRadius="md"
            border="2px solid"
            borderColor="gray.300"
            boxShadow="md"
          />
          <Text mt={2} fontSize="sm" color="gray.600">{`Tasks Successfully Performed: ${tasksCompleted} / ${totalTasks}`}</Text>
        </GridItem>

        <GridItem>
          <Divider />
        </GridItem>

        {/* Skills Section */}
        <GridItem ml={4}>
          <Heading size="md" mb={2}>
            Skills
          </Heading>
          <HStack wrap="wrap" spacing={4}>
            {skills &&
              Object.entries(skills).map(([skill, level]) => (
                <Tag key={skill} colorScheme="teal">{`${skill.toUpperCase()} (Level: ${level})`}</Tag>
              ))}
          </HStack>
        </GridItem>

        {/* Metrics Section */}
        <GridItem ml={4}>
          <Heading size="md" mb={2}>
            Metrics
          </Heading>
          <HStack wrap="wrap" spacing={4}>
            <Badge colorScheme="purple">{`Task Hours: ${currentTaskHours}`}</Badge>
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default MyProfile;
