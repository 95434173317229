import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Box,
  useToast,
  FormHelperText,
} from "@chakra-ui/react";
import { UserState } from "../../../Context/UserProvider";
import { LayoutState } from "../../../Context/LayoutProvider";
import { AuthState } from "../../../Context/AuthProvider";
import StatusDropDown from "../Dropdowns/StatusDropDown";
import PriorityDropDown, { PriorityIcon } from "../Dropdowns/PriorityDropDown";
import { findIndexOfTask } from "../../../utils/functions";
import AddComment from "../Comments/AddComment";
import AllTaskComments from "../Comments/AllTaskComments";
import MilestoneDropDown from "../Dropdowns/MilestoneDropDown";
import ReporterDropDown from "../Dropdowns/ReporterDropDown";
import AssigneeDropDown from "../Dropdowns/AssigneeDropDown";
import { priorityStyleLight, taskStatusStyleLight } from "./styles";
import { Expand, Minimize, Trash2, X } from 'lucide-react';

export default function ViewTaskModal({
  isOpen,
  onClose,
  task,
  setTask,
  allTasks,
  setAllTasks,
}) {
  const { screenWidth } = LayoutState();
  const {
    myTasks,
    reportTasks,
    setMyTasks,
    setReportTasks,
    setTaskIdRef,
    resetTaskIdRef,
    reloadTaskComments,
    updateTaskPriority,
    updateTaskStatus,
    updateTask,
    taskComments,
    deleteTasks,
  } = UserState();
  const { user } = AuthState();

  const [title, setTitle] = useState(task.title || "");
  const [description, setDescription] = useState(task.description || "");
  const [status, setStatus] = useState(task.status || "toDo");
  const [prevStatus, setPrevStatus] = useState(task.status || "toDo");
  const [priority, setPriority] = useState(task.priority || "");
  const [prevPriority, setPrevPriority] = useState(task.priority || "");
  const [projectId, setProjectId] = useState(task.projectId || "");
  const [reviewerId, setReviewerId] = useState(task.reviewerId || "");
  const [assigneeId, setAssigneeId] = useState(task.assigneeId || "");
  const [duration, setDuration] = useState(Number(task.duration) || 0);
  const [dueDate, setDueDate] = useState(task.dueDate || "");
  const [milestone, setMilestone] = useState(
    task.milestone || { id: "", title: "" }
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const toast = useToast();
  const indexOfMyTaskRef = useRef(-1);
  const indexOfReportTaskRef = useRef(-1);
  const indexOfTaskRef = useRef(-1);

  const panelColor = "#ebf8ffe0";

  const priorityText = {
    high: "High",
    medium: "Medium",
    low: "Low",
  };

  const taskStatusText = {
    done: "Done",
    inProgress: "In Progress",
    blocked: "Blocked",
    toDo: "To Do",
    review: "Review",
  };

  const handleSaveEditing = async (attrToUpdate) => {
    try {
      console.log(attrToUpdate);
      await updateTask(
        task,
        { ...attrToUpdate },
        indexOfMyTaskRef.current,
        indexOfReportTaskRef.current,
        indexOfTaskRef.current,
        allTasks,
        setAllTasks
      );
      toast({
        title: "Task updated successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Failed to update task.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleUpdateTaskStatus = async () => {
    const val = status;
    const res = await updateTaskStatus(
      task,
      status,
      prevStatus,
      indexOfMyTaskRef.current,
      indexOfReportTaskRef.current,
      indexOfTaskRef.current,
      allTasks,
      setPrevStatus,
      setAllTasks
    );

    if (!res?.success) {
      setStatus(prevStatus);
    } else {
      setPrevStatus(val);
    }
  };

  const modifiedOnClose = () => {
    if (indexOfMyTaskRef.current !== -1) {
      setMyTasks((tasks) => {
        const newTasks = tasks
          ?.filter((obj) => obj?.assigneeId === user?.firebaseUserId)
          ?.map((object) =>
            object.id !== task.id
              ? object
              : {
                  ...object,
                  reviewerId,
                  assigneeId,
                  milestone,
                  title,
                  description,
                  duration,
                  priority,
                  status,
                  dueDate,
                }
          );
        return newTasks;
      });
    }

    if (indexOfReportTaskRef.current !== -1) {
      setReportTasks((tasks) => {
        const newTasks = tasks
          ?.filter((obj) => obj?.reviewerId === user?.firebaseUserId)
          ?.map((object) =>
            object.id !== task.id
              ? object
              : {
                  ...object,
                  reviewerId,
                  assigneeId,
                  milestone,
                  title,
                  description,
                  duration,
                  priority,
                  status,
                  dueDate,
                }
          );
        return newTasks;
      });
    }

    if (indexOfTaskRef.current !== -1) {
      setAllTasks((tasks) => {
        const newTasks = tasks?.map((object) =>
          object.id !== task.id
            ? { ...object }
            : {
                ...object,
                reviewerId,
                assigneeId,
                milestone,
                title,
                description,
                duration,
                priority,
                status,
                dueDate,
              }
        );
        return newTasks;
      });
    }

    indexOfTaskRef.current = -1;
    indexOfMyTaskRef.current = -1;
    indexOfReportTaskRef.current = -1;

    resetTaskIdRef();

    if (
      description !== task.description ||
      title !== task.title ||
      duration !== task.duration ||
      (dueDate && dueDate !== task.dueDate)
    ) {
      const attrToUpdate = {};

      if (description !== task.description) {
        attrToUpdate.description = description;
      }

      if (title !== task.title) {
        attrToUpdate.title = title;
      }

      if (duration !== task.duration) {
        attrToUpdate.duration = duration;
      }

      if (dueDate && dueDate !== task.dueDate) {
        attrToUpdate.dueDate = dueDate;
      }

      console.log(attrToUpdate);

      handleSaveEditing(attrToUpdate);
    }

    setIsExpanded(false);
    onClose();
  };

  const handleDurationChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+(?=\d+(\.\d+)?)/, "");
    value = value.replace(/\.+/g, ".");
    value = value.replace(/[^\d.]/g, "");
    if (value === "" || value === ".") {
      value = "0";
    }
    setDuration(parseFloat(value));
  };

  const handleDeleteTask = async () => {
    try {
      await deleteTasks([task.id]);

      setMyTasks(
        (prevTasks) => prevTasks?.filter((t) => t.id !== task.id) || null
      );
      setReportTasks(
        (prevTasks) => prevTasks?.filter((t) => t.id !== task.id) || null
      );

      if (typeof setAllTasks === "function") {
        setAllTasks(
          (prevTasks) => prevTasks?.filter((t) => t.id !== task.id) || null
        );
      }

      toast({
        title: "Task deleted successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onClose();
    } catch (err) {
      toast({
        title: "Error deleting task.",
        description: err.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDueDateChange = (e) => {
    const selectedDate = e.target.value;
    console.log(selectedDate);
    setDueDate(selectedDate);
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const toggleModalSize = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (status && prevStatus && status !== prevStatus) {
      try {
        handleUpdateTaskStatus();
      } catch (err) {
        console.error(err);
      }
    }
  }, [status]);

  useEffect(() => {
    if (priority && prevPriority && priority !== prevPriority) {
      try {
        const val = priority;
        updateTaskPriority(
          task,
          priority,
          indexOfMyTaskRef.current,
          indexOfReportTaskRef.current,
          indexOfTaskRef.current,
          allTasks,
          setAllTasks
        );
        setPrevPriority(val);
      } catch (error) {
        console.error(error);
      }
    }
  }, [priority]);

  useEffect(() => {
    setTaskIdRef(task.id);
    reloadTaskComments();

    indexOfMyTaskRef.current = findIndexOfTask(task, myTasks);
    indexOfReportTaskRef.current = findIndexOfTask(task, reportTasks);
    indexOfTaskRef.current = findIndexOfTask(task, allTasks);

    setAssigneeId(task.assigneeId);
    setReviewerId(task.reviewerId);
    setPrevPriority(task.priority);
    setPrevStatus(task.status);
    setDueDate(task.dueDate || "");
  }, []);

  const IconBox = ({ icon: Icon, onClick, label }) => (
    <Box
      as={Icon}
      size="20px"
      cursor="pointer"
      _hover={{ color: "gray.700", bg: "gray.200", transform: "scale(1.1)" }}
      transition="all 0.2s"
      borderRadius="full"
      onClick={onClick}
      aria-label={label}
    />
  );

  return (
    <Modal isOpen={isOpen} onClose={modifiedOnClose} size={isExpanded ? "full" : "4xl"} isCentered={!isExpanded}>
      <ModalOverlay />
      <ModalContent 
      height={isExpanded ? "100vh" : "90vh"} 
      maxHeight={isExpanded ? "100vh" : "90vh"} 
      m={0}
      maxWidth={isExpanded ? "100vw" : undefined}
      width={isExpanded ? "100%" : undefined}
      >
        <Box display="flex" justifyContent="flex-end" p={3} bg="white" gap={3}>
          <IconBox
            icon={Trash2}
            onClick={handleDeleteTask}
            label="Delete task"
          />
          <IconBox 
            icon={isExpanded ? Minimize : Expand}  
            onClick={toggleModalSize} 
            label={isExpanded ? "Minimize modal" : "Expand modal"} 
          />
          <IconBox icon={X} onClick={modifiedOnClose} label="Close modal" />
        </Box>
        <ModalHeader  p={1} pt={0} pb={1} mb={1} mt={-2}>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fontSize="3xl"
            fontWeight="500"
            border="none"
            _focus={{ boxShadow: "none" }}
            placeholder="Task Title"
            autoFocus={false}
          />
        </ModalHeader>
        <ModalBody pt={2} display="flex" flexDirection="column" px={0} pb={0}>
          <Tabs
            isFitted
            variant="enclosed"
            flex={1}
            display="flex"
            flexDirection="column"
          >
            <TabList>
              <Tab
                _selected={{ bg: panelColor }}
                _focus={{ boxShadow: "none" }}
              >
                Details
              </Tab>
              <Tab
                _selected={{ bg: panelColor }}
                _focus={{ boxShadow: "none" }}
              >
                <Text pr={2}>Discussions</Text>
                <Text fontWeight={700}>
                  {taskComments?.length > 0 && ` •${taskComments.length}`}
                </Text>
              </Tab>
            </TabList>
            <TabPanels
              flex={1}
              overflowY="auto"
              overflowX="hidden"
              bgColor={panelColor}
            >
              <TabPanel>
                <VStack spacing={6} align="stretch">
                  <HStack>
                    <FormControl>
                      <AssigneeDropDown
                        assigneeId={assigneeId}
                        setAssigneeId={setAssigneeId}
                        projectId={projectId}
                        reviewerId={reviewerId}
                        forUpdation={true}
                        isTriggerBgWhite={true}
                      />
                      <FormHelperText>Assignee</FormHelperText>
                    </FormControl>
                    <FormControl>
                      <ReporterDropDown
                        reviewerId={reviewerId}
                        setReviewerId={setReviewerId}
                        assigneeId={assigneeId}
                        projectId={projectId}
                        forUpdation={true}
                        isTriggerBgWhite={true}
                      />
                      <FormHelperText>Reviewer</FormHelperText>
                    </FormControl>
                  </HStack>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      minH="70px"
                      bgColor={"white"}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={5}
                    />
                  </FormControl>

                  <HStack spacing={4} align="flex-start" wrap="wrap">
                    <FormControl flex={1}>
                      <Input
                        value={duration}
                        bgColor={"white"}
                        placeholder="Duration"
                        onChange={handleDurationChange}
                        type="text"
                        inputMode="decimal"
                        step="0.1"
                      />
                      <FormHelperText>Duration (hours)</FormHelperText>
                    </FormControl>

                    <FormControl flex={1} minW="150px">
                      <Input
                        value={dueDate}
                        bgColor="white"
                        placeholder="Select Due Date"
                        onChange={handleDueDateChange}
                        type="date"
                        min={getTodayDate()}
                      />
                      <FormHelperText>Due Date</FormHelperText>
                    </FormControl>
                  </HStack>

                  <HStack spacing={4} align="flex-start" wrap="wrap">
                    <FormControl flex={1}>
                      <StatusDropDown status={status} setStatus={setStatus}>
                        <Button
                          w="full"
                          bg={taskStatusStyleLight[status]}
                          color="black"
                          _hover={{ opacity: 0.8 }}
                        >
                          {taskStatusText[status]}
                        </Button>
                      </StatusDropDown>
                      <FormHelperText>Status</FormHelperText>
                    </FormControl>
                    <FormControl flex={1}>
                      <PriorityDropDown
                        priority={priority}
                        setPriority={setPriority}
                      >
                        <Button
                          w="full"
                          bg={priorityStyleLight[priority]}
                          color={priority ? "black" : "gray.800"}
                          _hover={{ opacity: 0.8 }}
                        >
                          <Box>
                            {priority ? (
                              <Box display="flex" alignItems="center" gap={1}>
                                <PriorityIcon priority={priority} />
                                <Text>{priorityText[priority]}</Text>
                              </Box>
                            ) : (
                              <Text color="gray.500">Select Priority</Text>
                            )}
                          </Box>
                        </Button>
                      </PriorityDropDown>
                      <FormHelperText>Priority</FormHelperText>
                    </FormControl>
                  </HStack>

                  <HStack>
                    <FormControl>
                      <FormLabel>Milestone</FormLabel>
                      <MilestoneDropDown
                        projectId={projectId}
                        milestone={milestone}
                        setMilestone={setMilestone}
                        task={task}
                        forUpdation={true}
                        setTask={setTask}
                        isTriggerBgWhite={true}
                      />
                    </FormControl>
                  </HStack>
                </VStack>
              </TabPanel>
              <TabPanel height="450px" display="flex" flexDirection="column">
                <Box flex={1} overflowY="auto" mb={4}>
                  <AllTaskComments task={task} setTask={setTask} />
                </Box>
                <Box mt="auto">
                  <AddComment setTask={setTask} />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

