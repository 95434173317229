import { createContext, useContext, useEffect, useState } from "react";

const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(0);
  const [isPhone, setIsPhone] = useState(false);
  const [menuButtonClicked,setMenuButtonClicked] = useState(false)
  console.log(setMenuButtonClicked)
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth <= 780) {
        setIsPhone(true);
      } else {
        setIsPhone(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        screenWidth,
        setScreenWidth,
        isPhone,
        setIsPhone,
        menuButtonClicked,
        setMenuButtonClicked
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const LayoutState = () => {
  return useContext(LayoutContext);
};

export default LayoutProvider;
