import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/DashboardComponents/Header";
import { Box, useDisclosure, useToast } from "@chakra-ui/react";
import SideBar from "../Components/DashboardComponents/SideBar";
import { UserState } from "../Context/UserProvider";
import { AuthState } from "../Context/AuthProvider";
import { LayoutState } from "../Context/LayoutProvider";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Main from "../Components/AssistantPageComponents/Main";

const Assistant = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { organizations, selectedOrganization, organizationMembers } =
    UserState();
  const { user, reloadUser } = AuthState();
  const reloadUserCountRef = useRef(0);
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    // Ensure the page starts from the top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      selectedOrganization?.id &&
      organizationId !== selectedOrganization?.id &&
      (selectedOrganization.isAiEnabled || selectedOrganization?.trialActive)
    ) {
      navigate(`/assistant/${selectedOrganization.id}`);
    } else {
      toast({
        description: "Assistant hasn't been activated for this organization.",
        isClosable: true,
        status: "warning",
        duration: 1500,
      });
      navigate(`/dashboard`);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (
      organizations &&
      user?.orgs &&
      organizations.length !== Object.keys(user.orgs).length
    ) {
      if (reloadUserCountRef.current < 1) {
        reloadUser();
        reloadUserCountRef.current++;
        setTimeout(() => {
          reloadUserCountRef.current = 0;
        }, 3000);
      }
    }
  }, [organizations]);

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      style={{ overflow: "hidden" }}
    >
      {organizations && selectedOrganization && organizationMembers && (
        <Box
          display="flex"
          maxWidth={"100vw"}
          minHeight={"calc(100vh - 60px)"}
          width={"100vw"}
          flexDirection={"column"}
        >
          <Box
            className="post-header"
            maxHeight="100vh"
            overflow="hidden"
            flex={1}
            margin={0}
            padding={0}
            display="flex"
            flexDirection="row"
          >
            <Main isOpen={isOpen} onClose={onClose} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Assistant;
