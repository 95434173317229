import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { getAuth, signOut } from "firebase/auth";
import { Box, useToast } from "@chakra-ui/react";
import useUser from "../hooks/useUser";
import useGetAccessToken from "../hooks/useGetAccessToken";
import useGetRefreshToken from "../hooks/useGetRefreshToken";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../Components/Miscellaneous/Loading/Loading";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const initialRender = useRef(true);

  const {
    refreshToken,
    loading: refreshTokenLoading,
    error: refreshTokenError,
  } = useGetRefreshToken(auth, initialRender); // handles user auth state changes like signin and signout / login logout

  const {
    accessToken,
    loading: accessTokenLoading,
    error: accessTokenError,
  } = useGetAccessToken(refreshToken, auth);

  const {
    user,
    setUser,
    loading: userLoading,
    error: userError,
    reload: reloadUser,
    preventUserFetch,
    allowUserFetch,
    createFirebaseUser,
    deleteFirebaseUser,
    updateUserSkills,
    saveUserData,
    reloadUserMetrics,
    isReloadingUserMetrics,
    userMetricsReloadComplete,
    leaveOrganization,
    findMembersDataOnPlatform,
    handle3rdPartyAuth,
    getUserData,
    checkUserExists,
    updateUserProfilePicture,
    isUpdatingProfilePicUrlRef,
    fetchMemberData
  } = useUser(auth, accessToken);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (refreshToken && auth && auth.currentUser) {
      if (!auth.currentUser.emailVerified && location.pathname !== "/verify") {
        // console.log("navigate to /verify");
        navigate("/verify");
        // setLoading(false)
      } else if (
        auth.currentUser.emailVerified &&
        location.pathname === "/verify"
      ) {
        // console.log("navigate to /dashboard");
        navigate("/dashboard");
      } else if (location.pathname === "/" || location.pathname === "/auth") {
        // console.log("navigate to dashboard");
        navigate("/dashboard");
      }
    } else if (!auth || !auth.currentUser) {
      if (location.pathname !== "/auth" && location.pathname !== "/") {
        // console.log("navigating to /auth");
        navigate("/auth");
      }
      setLoading(false);
    }
  }, [refreshToken, auth, navigate]);

  useEffect(() => {
    if (!refreshTokenLoading && !refreshToken) {
      setLoading(false);
    }

    if (!userLoading && !accessTokenLoading && !refreshTokenLoading) {
      setLoading(false);
    }
  }, [userLoading, accessTokenLoading, refreshTokenLoading]);

  useEffect(() => {
    if (refreshTokenError || accessTokenError || userError) {
      setError(refreshTokenError || accessTokenError || userError);
    }
  }, [accessTokenError, refreshTokenError, userError]);

  useEffect(() => {
    if (error) {
      const logout = async () => {
        await signOut(auth); // this will trigger onAuthStatusChanged listener in getRefreshToken.js which will set refresh to null which navigate to /auth and will set access token to null which will set user to null
      };
      if (auth.currentUser) {
        logout();
        // console.log("Kindly login again to continue");
        toast({
          // title: "Error occured.",
          description: `${error}.`,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      } else {
        // console.log(`Unauthorized. Need to login before accessing this page.`);
        toast({
          description: `Kindly login again to continue.`,
          // status: "success",
          duration: 4000,
          isClosable: true,
        });
      }
      // console.log("navigating to auth signin");
      navigate("/auth");
      setLoading(false);
    }
  }, [error]);

  const value = {
    auth,
    user,
    accessToken,
    refreshToken,
    isReloadingUserMetrics,
    isUpdatingProfilePicUrlRef,
    setUser,
    reloadUser,
    preventUserFetch,
    allowUserFetch,
    createFirebaseUser,
    deleteFirebaseUser,
    saveUserData,
    reloadUserMetrics,
    userMetricsReloadComplete,
    updateUserSkills,
    leaveOrganization,
    findMembersDataOnPlatform,
    handle3rdPartyAuth,
    getUserData,
    checkUserExists,
    updateUserProfilePicture,
    fetchMemberData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? (
        children
      ) : (
        <Box display={"flex"} height={"100vh"} width={"100vw"}>
          <Loading />
        </Box>
      )}
    </AuthContext.Provider>
  );
};

export const AuthState = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
