import { useEffect, useRef, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { NOTIFICATION_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";
import { io } from "socket.io-client";

const useNotifications = () => {
  const { accessToken, user } = AuthState();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(true);

  const socketRef = useRef(null);

  const sendNotification = async (message, userIds) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.post(
        `${baseUrl}/api/send_notification`,
        { message, user_ids: userIds },
        config
      );
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}/api/get_notifications/1/10`,
        config
      );
      const newNotifications = response?.data?.data;
      setNotifications((prev) => [
        ...(prev || []),
        ...(newNotifications || []),
      ]);
      setHasMore(newNotifications.length === 10);
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = () => {
    fetchNotifications();
  };

  const markAllNotificationsAsRead = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.put(
        `${baseUrl}/api/mark_all_notifications_as_read`,
        {},
        config
      );

      setHasUnreadNotifications(false);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  useEffect(() => {
    if (!accessToken && user?.firebaseUserId) {
      return;
    }

    socketRef.current = io("http://localhost:3009");
    socketRef.current.emit("join", { user_id: user?.firebaseUserId });

    socketRef.current.on("new_notification", (notification) => {
      setNotifications((prev) => [notification, ...prev]);
      setHasUnreadNotifications(true);
    });

    fetchNotifications();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [user]);

  useEffect(() => {
    const unreadNotification = notifications.some(
      (notification) => !notification.read
    );

    // if(unreadNotification){
    //   setHasUnreadNotifications(true);
    // }

    setHasUnreadNotifications(true);
  }, [notifications]);

  return {
    notifications,
    isLoading,
    hasMore,
    hasUnreadNotifications,
    handleLoadMore,
    sendNotification,
    markAllNotificationsAsRead,
    setHasUnreadNotifications,
  };
};

export default useNotifications;
