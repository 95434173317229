import axios from "axios";
import { TASK_BASE_URL as baseUrl } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";
import { useEffect, useRef, useState } from "react";

const useTasks = (
  myTasks,
  reportTasks,
  setMyTasks,
  setReportTasks,
  selectedOrganization
) => {
  const { user, accessToken, reloadUserMetrics } = AuthState();
  const [taskComments, setTaskComments] = useState(null);
  const [error, setError] = useState(null);
  const [reloadTaskCommentsFlag, setReloadTaskCommentsFlag] = useState(0);
  const taskIdRef = useRef(null);
  const commentLimitRef = useRef(10);
  const commentPageRef = useRef(1);
  const loadingNewPageRef = useRef(false);

  const setTaskIdRef = (taskId) => {
    if (!taskIdRef) {
      setError("taskId is falsey.");
      return;
    }
    taskIdRef.current = taskId;
  };

  const resetTaskIdRef = () => {
    commentPageRef.current = 1;
    commentLimitRef.current = 10;
    loadingNewPageRef.current = false;
    taskIdRef.current = null;
  };

  const getProjectActiveSprintTasks = async (projectId, sprintId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.get(
        `${baseUrl}/projects/${projectId}/sprints/${sprintId}/tasks`,
        config
      );

      if (response?.data?.status === "success") {
        const sprintTasks = response?.data?.data;

        return sprintTasks;
      } else {
        throw new Error({ customMessage: "Couldn't fetch sprint tasks." });
      }
    } catch (err) {
      console.error("Error while fetching sprint tasks.", err);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      if (err.customMessage) {
        setError(err.customMessage);
        return;
      }
      setError("Error while fetching sprint tasks. Reload.");
    }
  };

  const getMilestonesByProject = async (
    projectId,
    shouldGetDoneTasks = false
  ) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
        params: {
          shouldGetDoneTasks,
        },
      };

      const response = await axios.get(
        `${baseUrl}/projects/${projectId}/milestones`,
        config
      );

      const projectMilestones = response?.data?.milestones;

      if (projectMilestones && Array.isArray(projectMilestones)) {
        return projectMilestones;
      } else {
        throw new Error("Project Milestones not found");
      }
    } catch (err) {
      console.error("Error while fetching project milestones", err);
    }
  };

  const getTasksByMilestone = async (milestoneId) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.get(
        `${baseUrl}/milestones/${milestoneId}/tasks`,
        config
      );

      const milestoneTasks = response?.data?.tasks;

      if (milestoneTasks && Array.isArray(milestoneTasks)) {
        return { tasks: milestoneTasks };
      } else {
        return { message: "No tasks found for this milestone." };
      }
    } catch (err) {
      console.error("Error while fetching project milestones", err);
    }
  };

  const createMilestone = async (milestoneData) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/create_milestone`,
        milestoneData,
        config
      );

      const newMilestone = response?.data?.data;

      if (newMilestone && newMilestone.id) {
        return newMilestone;
      } else {
        throw new Error("Couldn't create new milestone");
      }
    } catch (err) {
      console.error("Error while fetching project milestones", err);
    }
  };

  const modifyMilestone = async (milestoneId, dataToUpdate) => {
    if (!milestoneId) {
      console.error("milestone id not present", milestoneId);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/modify_milestone/${milestoneId}`,
        dataToUpdate,
        config
      );

      const updatedMilestone = response?.data?.milestone;

      if (updatedMilestone && updatedMilestone.id) {
        return updatedMilestone;
      } else {
        throw new Error("Couldn't update milestone");
      }
    } catch (err) {
      console.error("Error while fetching project milestones", err);
    }
  };

  const deleteMilestones = async (milestoneIds) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      if (!Array.isArray(milestoneIds)) {
        milestoneIds = [milestoneIds];
      }

      const response = await axios.delete(`${baseUrl}/delete_milestones`, {
        data: { milestone_ids: milestoneIds },
        ...config,
      });

      if (response?.data?.message === "Milestones deleted successfully") {
        return "Milestones deleted";
      } else {
        throw new Error("Failed to delete milestones");
      }
    } catch (err) {
      console.error("Error while deleting project milestones", err);
      return "error";
    }
  };

  const updateTaskMilestone = async (taskId, milestoneId) => {
    if (!milestoneId || !taskId) {
      console.error("milestone id or taskId not present", milestoneId, taskId);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/update_task_milestone`,
        { taskId, milestoneId },
        config
      );

      const message = response?.data?.message;

      if (message === "Task milestone updated successfully") {
        return message;
      } else {
        throw new Error("Couldn't update milestone");
      }
    } catch (err) {
      console.error("Error while fetching project milestones", err);
    }
  };

  const updateTaskAssignee = async (assigneeId) => {
    if (!assigneeId || !taskIdRef.current) {
      console.error("assignee id or taskId not present", assigneeId);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/update_task_assignee`,
        { taskId: taskIdRef.current, assigneeId },
        config
      );

      const message = response?.data?.message;

      if (message === "Task assignee updated successfully") {
        return message;
      } else {
        throw new Error("Couldn't update assignee");
      }
    } catch (err) {
      console.error("Error while updating task assignee", err);
      return "";
    }
  };

  const updateTaskReviewer = async (reviewerId) => {
    if (!reviewerId || !taskIdRef.current) {
      console.log(reviewerId);
      console.log(taskIdRef.current);

      console.error("assignee id or taskId not present", reviewerId);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/update_task_reporter`,
        { taskId: taskIdRef.current, reviewerId },
        config
      );

      const message = response?.data?.message;

      if (message === "Task reviewer updated successfully") {
        return message;
      } else {
        throw new Error("Couldn't update reviewer");
      }
    } catch (err) {
      console.error("Error while updating task reviewer", err);
      return "";
    }
  };

  const removeTaskAttachment = async (attachmentUrl) => {
    try {
      if (!taskIdRef.current || !attachmentUrl) {
        throw new Error("Couldn't remove task attachment.");
      }

      const formData = new FormData();
      formData.append("task_id", taskIdRef.current);
      formData.append("attachment_url", attachmentUrl);

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/delete/attachment`,
        formData,
        config
      );

      if (response.data.message === "Attachment deleted successfully") {
        const updatedTask = response.data.updatedTask;

        let couldUpdateFlag = false;

        setMyTasks((tasks) => {
          const updatedTasks = tasks?.map((task) =>
            task.id === taskIdRef.current ? updatedTask : task
          );
          return updatedTasks;
        });

        if (!couldUpdateFlag) {
          setReportTasks((tasks) => {
            const updatedTasks = tasks?.map((task) =>
              task.id === taskIdRef.current ? updatedTask : task
            );
            return updatedTasks;
          });
        }
      } else {
        throw new Error(response.data.error || "Failed to delete attachment");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const uploadCommentAttachment = async (file, commentId) => {
    try {
      if (!taskIdRef.current) {
        throw new Error("taskId is falsey.");
      }

      if (!file) {
        throw new Error("No file selected.");
      }

      if (!commentId) {
        throw new Error("Need comment id to add attachment to comment");
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("task_id", taskIdRef.current);
      formData.append("comment_id", commentId);

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/upload/attachment`,
        formData,
        config
      );
      const attachment = response.data.destination_blob_name;

      if (response.status !== 200) {
        throw new Error(response.data.error || "File upload failed.");
      }

      setTaskComments((currentComments) => {
        if (
          currentComments &&
          Array.isArray(currentComments) &&
          currentComments.length > 0
        ) {
          const updatedComments = currentComments.map((comment) => {
            if (comment.id === commentId) {
              comment.attachment = attachment;
            }
            return comment;
          });

          return updatedComments;
        }
      });

      return response.data.destination_blob_name;
    } catch (error) {
      setError(error.message);
    }
  };

  const addTaskComment = async (content) => {
    try {
      if (!taskIdRef.current) {
        throw new Error("Task Id Ref is falsey");
      }

      if (content === "" || !content) {
        throw new Error("Cannot add an empty comment.");
      }

      const commentData = {
        taskId: taskIdRef.current,
        content,
      };

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/create_comment`,
        commentData,
        config
      );

      const newComment = response.data.data;

      setTaskComments((prevComments) =>
        prevComments && Array.isArray(prevComments)
          ? [{ ...newComment, author: user }, ...prevComments]
          : [{ ...newComment, author: user }]
      );

      return newComment;
    } catch (error) {
      setError(error);
    }
  };

  const deleteTaskComment = async (commentId) => {
    try {
      if (!taskIdRef.current) {
        throw new Error("Task Id Ref is falsey");
      }

      if (!commentId) {
        throw new Error("Comment Id is falsey.");
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.delete(
        `${baseUrl}/delete_comment?id=${commentId}`,
        config
      );

      setTaskComments((prevComments) => {
        let commentsAfterDeletion = [];
        if (prevComments && Array.isArray(prevComments)) {
          prevComments.map((comment) => {
            if (comment.id !== commentId) {
              commentsAfterDeletion.push(comment);
            }
          });
        }
        return commentsAfterDeletion;
      });

      return response.data.message;
    } catch (error) {
      setError(error);
    }
  };

  const updateTaskComment = async (commentId, content) => {
    try {
      if (!taskIdRef.current) {
        throw new Error("Task Id Ref is falsey");
      }

      if (!commentId) {
        throw new Error("Comment Id is falsey.");
      }

      if (content === "" || !content) {
        throw new Error("Try deleting the comment instead.");
      }

      const commentData = {
        commentId,
        content,
      };

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/update_comment`,
        commentData,
        config
      );

      const updatedComment = response.data.data;

      setTaskComments((prevComments) => {
        if (prevComments && Array.isArray(prevComments)) {
          return prevComments.map((comment) =>
            comment.id === updatedComment.id ? updatedComment : comment
          );
        }
        return [{ ...updatedComment }];
      });

      return updatedComment;
    } catch (error) {
      setError(error);
    }
  };

  const reloadTaskComments = (page = 1, limit = 10) => {
    commentLimitRef.current = limit;
    commentPageRef.current = page;
    setReloadTaskCommentsFlag((prevValue) => prevValue + 1);
  };

  const loadMoreTaskComments = () => {
    commentPageRef.current = commentPageRef.current + 1;
    loadingNewPageRef.current = true;
    setReloadTaskCommentsFlag((prevValue) => prevValue + 1);
  };

  const createTask = async (
    taskData,
    requestFromProjectPage = false,
    setAllTasks
  ) => {
    try {
      if (!taskData.projectId) {
        throw new Error("You need to assign a project to new tasks.");
      }

      if (!taskData.title) {
        throw new Error("Cannot create new tasks without a title.");
      }

      if (
        taskData.duration &&
        typeof taskData.duration === "string" &&
        isNaN(taskData.duration)
      ) {
        throw new Error(
          "Duration is number of hours. It cannot be empty and must be a +ve number."
        );
      }

      taskData.duration = Number(taskData.duration) || 0;

      if (
        !selectedOrganization.isPersonal &&
        taskData.assigneeId &&
        taskData.reviewerId &&
        taskData.assigneeId === taskData.reviewerId
      ) {
        throw new Error(
          "Assignee and Reporter cannot be the same for non-personal organizations."
        );
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/create_task?isPersonal=${selectedOrganization.isPersonal}`,
        taskData,
        config
      );

      const newTask = response?.data?.data;
      if (!newTask) {
        throw new Error("new task object is empty.");
      }

      if (requestFromProjectPage && setAllTasks) {
        // update all tasks
        setAllTasks((tasks) => [{ ...newTask }, ...(tasks || [])]);
      }

      if (user.firebaseUserId === newTask.assigneeId) {
        // update mytasks array
        setMyTasks((tasks) => [{ ...newTask }, ...(tasks || [])]);
      } else if (user.firebaseUserId === newTask.reviewerId) {
        // update reporttasks array
        setReportTasks((tasks) => [{ ...newTask }, ...(tasks || [])]);
      }
      return newTask;
    } catch (error) {
      console.log(error);
      // setError(error);
      throw new Error("Couldn't create task.");
    }
  };
  const updateTaskSuccessStatus = async (
    successStatus,
    requestFromProjectPage,
    setAllTasks
  ) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const putData = {
        taskId: taskIdRef.current,
        isSuccess: successStatus === "Success",
      };

      const response = await axios.put(
        `${baseUrl}/task_success_status`,
        putData,
        config
      );

      if (response.status !== 200) {
        throw new Error(`Received status code: ${response.status}`);
      }

      if (selectedOrganization.isPersonal) {
        setMyTasks((tasks) => {
          if (!tasks) {
            return null;
          }
          return tasks.map((task) => {
            if (taskIdRef.current !== task.id) {
              return task;
            }
            return { ...task, isSuccess: successStatus === "Success" };
          });
        });
        // reloadUserMetrics();
      }

      setReportTasks((tasks) => {
        if (!tasks) {
          return null;
        }
        return tasks.map((task) => {
          if (taskIdRef.current !== task.id) {
            return task;
          }
          return { ...task, isSuccess: successStatus === "Success" };
        });
      });

      if (requestFromProjectPage) {
        setAllTasks((tasks) => {
          if (!tasks) {
            return null;
          }
          return tasks.map((task) => {
            if (taskIdRef.current !== task.id) {
              return task;
            }
            return { ...task, isSuccess: successStatus === "Success" };
          });
        });
      }
    } catch (err) {
      // console.error("Error while updating task success status:", err);
      setError("Error while updating task success status");
    }
  };

  const updateTaskStatus = async (
    task,
    newStatus,
    prevStatus,
    indexOfMyTask = -1,
    indexOfReportTask = -1,
    indexOfTask = -1,
    allTasks = [],
    setPrevStatus = () => {},
    setAllTasks = () => {}
  ) => {
    if (!task.assigneeId) {
      setError(`Can't update task status. Task has no assignee.`);
      return { sucess: false };
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const putData = {
        taskId: task.id,
        status: newStatus,
      };

      const response = await axios.put(
        `${baseUrl}/update_task_status`,
        putData,
        config
      );

      if (response.status !== 200) {
        throw new Error("recieved status code: ", response.status);
      }

      if (prevStatus !== "done" && newStatus === "done") {
        const postData = {
          taskId: task.id,
        };
        const response2 = await axios.post(
          `${baseUrl}/task_completed`,
          postData,
          config
        );
      } else if (prevStatus === "done" && newStatus !== "done") {
        const postData = {
          taskId: task.id,
        };
        const response2 = await axios.post(
          `${baseUrl}/task_completed_undo`,
          postData,
          config
        );
      }

      if (
        (prevStatus !== "done" && newStatus === "done") ||
        (prevStatus === "done" && newStatus !== "done")
      ) {
        // reloadUserMetrics();
      }

      setPrevStatus(newStatus);

      if (
        allTasks &&
        Array.isArray(allTasks) &&
        allTasks.length > 0 &&
        indexOfTask !== -1
      ) {
        allTasks[indexOfTask] = {
          ...allTasks[indexOfTask],
          status: newStatus,
        };

        setAllTasks([...allTasks]);
      }

      if (indexOfMyTask !== -1 && Array.isArray(myTasks)) {
        myTasks[indexOfMyTask] = {
          ...myTasks[indexOfMyTask],
          status: newStatus,
        };

        setMyTasks([...myTasks]);
      }

      if (indexOfReportTask !== -1) {
        reportTasks[indexOfReportTask] = {
          ...reportTasks[indexOfReportTask],
          status: newStatus,
        };

        setReportTasks([...reportTasks]);
      }

      // toast({
      //   title: "Task status updated successfully.",
      //   status: "success",
      //   duration: 2000,
      //   isClosable: true,
      // });
      return { success: true };
    } catch (err) {
      // toast({
      //   title: "Error occurred while updating task status.",
      //   description: `${err.message}`,
      //   status: "error",
      //   duration: 2000,
      //   isClosable: true,
      // });
      // setError(`${err.message}`);
      console.error("Error while updating task status:", err);
    }
  };

  const updateTaskPriority = async (
    task,
    newPriority,
    indexOfMyTask = -1,
    indexOfReportTask = -1,
    indexOfTask = -1,
    allTasks = [],
    setAllTasks = () => {}
  ) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const putData = {
        taskId: task.id,
        priority: newPriority,
      };

      const response = await axios.put(
        `${baseUrl}/update_task_priority`,
        putData,
        config
      );

      if (response.status !== 200) {
        throw new Error("recieved status code: ", response.status);
      }

      if (
        allTasks &&
        Array.isArray(allTasks) &&
        allTasks.length > 0 &&
        indexOfTask !== -1
      ) {
        allTasks[indexOfTask] = {
          ...allTasks[indexOfTask],
          priority: newPriority,
        };

        setAllTasks([...allTasks]);
      }

      if (indexOfMyTask !== -1) {
        myTasks[indexOfMyTask] = {
          ...myTasks[indexOfMyTask],
          priority: newPriority,
        };

        setMyTasks([...myTasks]);
      }

      if (indexOfReportTask !== -1) {
        reportTasks[indexOfReportTask] = {
          ...reportTasks[indexOfReportTask],
          priority: newPriority,
        };

        setReportTasks([...reportTasks]);
      }

      return { status: "success" };
    } catch (err) {
      // setError("Error occured while updating task priority.");
      console.error("Error while updating task priority:", err);
    }
  };

  const updateTask = async (
    task,
    attrToUpdate,
    indexOfMyTask = -1,
    indexOfReportTask = -1,
    indexOfTask = -1,
    allTasks = [],
    setAllTasks = () => {}
  ) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const putData = {
        taskId: task.id,
        ...attrToUpdate,
      };

      const response = await axios.put(
        `${baseUrl}/update_task`,
        putData,
        config
      );

      if (response.status !== 200) {
        throw new Error("recieved status code: ", response.status);
      }

      // toast({
      //   title: "Task elements (title, description, duration) updated successfully.",
      //   status: "success",
      //   duration: 2000,
      //   isClosable: true,
      // });

      if (
        allTasks &&
        Array.isArray(allTasks) &&
        allTasks.length > 0 &&
        indexOfTask !== -1
      ) {
        allTasks[indexOfTask] = {
          ...allTasks[indexOfTask],
          description: attrToUpdate.description || task.description,
          title: attrToUpdate.title || task.title,
          duration: attrToUpdate.duration || task.duration,
        };

        setAllTasks([...allTasks]);
      }

      if (indexOfMyTask !== -1) {
        myTasks[indexOfMyTask] = {
          ...myTasks[indexOfMyTask],
          description: attrToUpdate.description || task.description,
          title: attrToUpdate.title || task.title,
          duration: attrToUpdate.duration || task.duration,
        };

        setMyTasks([...myTasks]);
      }

      if (indexOfReportTask !== -1) {
        reportTasks[indexOfReportTask] = {
          ...reportTasks[indexOfReportTask],
          description: attrToUpdate.description || task.description,
          title: attrToUpdate.title || task.title,
          duration: attrToUpdate.duration || task.duration,
        };

        setReportTasks([...reportTasks]);
      }
    } catch (err) {
      // toast({
      //   title:
      //     "Error occurred while updating elements (title, description, milestone).",
      //   description: `${err.message}`,
      //   status: "error",
      //   duration: 2000,
      //   isClosable: true,
      // });
      setError("Error occured while updating task.");
      throw new Error(err);
    }
  };

  const deleteTasks = async (taskIds) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.delete(`${baseUrl}/delete_tasks`, {
        data: { taskIds },
        ...config,
      });

      if (response.status !== 200) {
        throw new Error("received status code: ", response.status);
      }
      // reloadUserMetrics();

      return { status: "success" };
    } catch (err) {
      setError("Error occurred while deleting tasks.");
    }
  };

  const getProjectTasks = async (viewingProjectId, setProjectTasks) => {
    try {
      if (!viewingProjectId) {
        throw new Error("Cannot fetch project tasks without viewingProjectId.");
      }

      if (!accessToken) {
        throw new Error(
          "Access token is falsey. Cannot fetch project id. Retry or signout and signin"
        );
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.get(
        `${baseUrl}/get_project_tasks?projectId=${viewingProjectId}`,
        config
      );

      const projectTasks = response.data.data;
      setProjectTasks(projectTasks);

      return;
    } catch (err) {
      setError("Error while fetching project tasks");
    }
  };

  useEffect(() => {
    if (!taskIdRef.current) {
      return;
    }

    const loadTaskComments = async () => {
      try {
        if (!taskIdRef.current) {
          throw new Error("Task Id Ref is falsey");
        }

        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "x-org-id": selectedOrganization.id,
          },
        };

        const response = await axios.get(
          `${baseUrl}/load_task_comments?id=${taskIdRef.current}&page=${commentPageRef.current}&limit=${commentLimitRef.current}`,
          config
        );

        const newComments = response.data.data;

        if (loadingNewPageRef && loadingNewPageRef.current) {
          setTaskComments((prevComments) => [...prevComments, ...newComments]);
          loadingNewPageRef.current = false;
        } else {
          setTaskComments(newComments);
        }

        return response.data.data;
      } catch (error) {
        setError(error);
      }
    };

    loadTaskComments();
  }, [reloadTaskCommentsFlag]);

  return {
    getProjectTasks,
    createTask,
    deleteTasks,
    setTaskIdRef,
    resetTaskIdRef,
    addTaskComment,
    deleteTaskComment,
    updateTaskComment,
    reloadTaskComments,
    loadMoreTaskComments,
    taskComments,
    uploadCommentAttachment,
    removeTaskAttachment,
    setTaskComments,
    updateTaskSuccessStatus,
    updateTaskPriority,
    updateTaskStatus,
    updateTask,
    getMilestonesByProject,
    createMilestone,
    modifyMilestone,
    deleteMilestones,
    updateTaskMilestone,
    updateTaskAssignee,
    updateTaskReviewer,
    getProjectActiveSprintTasks,
    getTasksByMilestone,
    error,
    setError,
  };
};

export default useTasks;
