import { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { ORG_BASE_URL as baseUrl, USER_BASE_URL } from "../baseurls";
import { AuthState } from "../Context/AuthProvider";

const useOrganizationMembers = (
  selectedOrganization,
  reloadOrganizations,
  projects
) => {
  const { accessToken } = AuthState();
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(0);

  const reload = () => {
    setReloadFlag((prevValue) => prevValue + 1);
  };

  const addMembersToOrganization = async (members) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.post(
        `${baseUrl}/add_members_to_org`,
        { members, orgId: selectedOrganization.id },
        config
      );

      reload();
    } catch (err) {
      // console.log(err);
      setError("Error while fetching organization members");
      throw new Error("Failed to add members");
    }
  };

  const removeMembersFromOrganization = async (memberIds) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/remove_members_from_org`,
        { memberIds, orgId: selectedOrganization.id },
        config
      );

      reloadOrganizations();
      // reload();
    } catch (err) {
      setError(
        err.response.data.error || "Error removing members from organization"
      );
      throw new Error(err.message);
    }
  };

  const updateMemberRole = async (memberId, newRole) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.put(
        `${baseUrl}/update_member_role`,
        { memberId, orgId: selectedOrganization.id, role: newRole },
        config
      );

      reload();
    } catch (err) {
      // console.log(err);
      setError(err.response.data.error || "Error updating member role");
      throw new Error("Failed to update member role");
    }
  };

  const getOrganizationMembersData = async (shouldUpdateState = true) => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const response = await axios.get(
        `${baseUrl}/fetch_org_members_data?orgId=${selectedOrganization.id}`,
        config
      );

      const organizationMembersData = response?.data?.members;

      if (organizationMembersData && Array.isArray(organizationMembersData)) {
        // console.log(organizationMembersData);
        if (shouldUpdateState) {
          setOrganizationMembers(organizationMembersData);
        }
      } else {
        throw new Error("OrganizationMembers Data not found");
      }
      return organizationMembersData;
    } catch (err) {
      // console.log(err);
      setError("Error while fetching organization members");
    } finally {
      setLoading(false);
    }
  };

  const getProjectsMembersData = async () => {
    if (!projects || projects.length === 0) return;

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-org-id": selectedOrganization.id,
        },
      };

      const membersDataPromised = projects.map(
        (project) =>
          new Promise(async (resolve, reject) => {
            try {
              const response = await axios.get(
                `${USER_BASE_URL}/projects_members_data?projectId=${project.id}`,
                config
              );
              resolve(response);
            } catch (error) {
              reject(error);
            }
          })
      );

      const responses = await Promise.all(membersDataPromised);

      const organizationMembersData = responses
        .map((response) => response?.data?.members)
        .filter((data) => Array.isArray(data))
        .flat();

      if (
        organizationMembersData &&
        Array.isArray(organizationMembersData) &&
        organizationMembersData.length > 0
      ) {
        // merge and remove duplicates. duplicates are present when a user is added to multiple projects, so for projectId field we will replace it with an array of projectIds
        const uniqueMembers = organizationMembersData.reduce((acc, member) => {
          const existingMember = acc.find(
            (m) => m.firebaseUserId === member.firebaseUserId
          );
          if (existingMember) {
            existingMember.projectIds.push(member.projectId);
          } else {
            acc.push({ ...member, projectIds: [member.projectId] });
          }
          return acc;
        }, []);

        setOrganizationMembers(uniqueMembers || []);
      } else {
        throw new Error("OrganizationMembers Data not found");
      }
    } catch (err) {
      console.error(err); // log the error
      // setError("Error while fetching organization members");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedOrganization) {
      setOrganizationMembers([]);
      // if (selectedOrganization === undefined) {
      //   setError(
      //     "Cannot fetch review tasks data without selected organization data"
      //   );
      // }
      return;
    }

    getProjectsMembersData();
  }, [selectedOrganization, reloadFlag, projects]);

  // subscribe to an interval that calls the getProjectsMembersData every 58 mins.
  useEffect(() => {
    const intervalId = setInterval(() => {
      getProjectsMembersData();
    }, 58 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [projects]);

  return {
    organizationMembers,
    setOrganizationMembers,
    getOrganizationMembersData,
    addMembersToOrganization,
    removeMembersFromOrganization,
    updateMemberRole,
    loading,
    error,
    setError,
    reload,
  };
};

export default useOrganizationMembers;
