import {
  Avatar,
  Box,
  Button,
  Image,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserState } from "../../../Context/UserProvider";
import { AuthState } from "../../../Context/AuthProvider";
import { IoMdDocument } from "react-icons/io";
import ExpandedCommentView from "./ExpandedCommentView";
import { format } from "date-fns"; // Import format from date-fns

const AllTaskComments = ({ setTask, task }) => {
  const { user } = AuthState();
  const {
    taskComments,
    deleteTaskComment,
    updateTaskComment,
    loadMoreTaskComments,
  } = UserState();
  const [editingComment, setEditingComment] = useState(null);
  const [updatedCommentContent, setUpdatedCommentContent] = useState("");
  const [expandedComment, setExpandedComment] = useState(null);

  const handleCommentExpansion = (e) => {
    const commentId = e.target.id;
    const commentToExpand = taskComments.find(
      (comment) => comment.id === commentId
    );
    if (commentToExpand?.id) {
      setExpandedComment(commentToExpand);
    } else {
      setExpandedComment(null);
    }
  };

  const handleCloseExpanded = () => {
    setExpandedComment(null);
  };

  const handleCommentDelete = async (e, comment) => {
    try {
      await deleteTaskComment(comment.id);
      setTask((viewingTask) => ({
        ...viewingTask,
        commentsCount: viewingTask.commentsCount - 1,
      }));
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleCommentEditClicked = async (e, comment) => {
    if (!editingComment) {
      setUpdatedCommentContent(comment.content);
      setEditingComment(comment.id);
      return;
    }

    if (updatedCommentContent === "") {
      await handleCommentDelete(e, comment);
    } else {
      if (updatedCommentContent !== comment.content) {
        try {
          await updateTaskComment(comment.id, updatedCommentContent);
        } catch (error) {
          console.error("Error updating comment:", error);
        }
      }
      setUpdatedCommentContent("");
    }
    setEditingComment(null);
  };

  useEffect(() => {
    if (
      taskComments &&
      Array.isArray(taskComments) &&
      taskComments.length > 0
    ) {
      // update task comments that have attachments with proper url
    }
  }, [taskComments]);

  return (
    <Box
      marginTop={1}
      paddingTop={0}
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      paddingY={2}
      height="100%"
      flex={1}
    >
      <Box
        marginTop={0}
        paddingTop={0}
        display="flex"
        flexDir="column"
        flex={1}
      >
        {!taskComments ||
        (Array.isArray(taskComments) && taskComments.length <= 0) ? (
          <Text color="gray.500">No comments under this task.</Text>
        ) : (
          <VStack spacing={2} overflow="auto" alignItems="stretch" width="100%">
            {taskComments.map((comment) => {
              if (comment.authorId === user.firebaseUserId) {
                comment.author = user;
              }

              return (
                <Box
                  key={comment.id}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  mb={2}
                >
                  <Box display="flex" alignItems="flex-start" width="100%">
                    <Avatar
                      src={comment.author?.imageUrl}
                      name={comment.author ? `${comment.author.firstName} ${comment.author.lastName}` : ""}
                      size="sm"
                      marginRight={2}
                      marginTop={3}
                    />
                    <Box
                      bgColor="white"
                      color="gray.800"
                      padding={1}
                      margin={0}
                      borderRadius="lg"
                      boxShadow="md"
                      borderWidth="1px"
                      borderColor="gray.200"
                      width="fit-content"
                      maxWidth="100%"
                    >
                      <Text
                        fontSize="xs"
                        fontWeight="600"
                        color="gray.600"
                        mb={1}
                        ml={2}
                      >
                        {comment.author ? `${comment.author.firstName} ${comment.author.lastName}` : ""}
                      </Text>
                      {editingComment === comment.id ? (
                        <Input
                          type="text"
                          value={updatedCommentContent}
                          onChange={(e) =>
                            setUpdatedCommentContent(e.target.value)
                          }
                          size="sm"
                          mb={2}
                        />
                      ) : (
                        <Text fontSize="sm" mb={2} ml={2}>
                          {comment.content}
                        </Text>
                      )}
                      {comment.attachment && (
                        <Box mb={2}>
                          {!comment.attachment.includes(".pdf") ? (
                            <Image
                              id={comment.id}
                              src={`${comment.attachment}`}
                              onClick={handleCommentExpansion}
                              maxHeight="50px"
                              _hover={{ cursor: "pointer" }}
                              alt={`${comment.attachment.substring(comment.attachment.indexOf("_"), comment.attachment.indexOf("?")).substring(0, 10)}...`}
                            />
                          ) : (
                            <Box display="flex" alignItems="center" _hover={{ cursor: "pointer" }}>
                              <IoMdDocument />
                              <Link href={`${comment.attachment}`} ml={1} fontSize="sm">
                                {comment.attachment.substring(
                                  comment.attachment.indexOf("_") + 1,
                                  comment.attachment.indexOf("?")
                                ).substring(0, 10)}...
                              </Link>
                            </Box>
                          )}
                        </Box>
                      )}
                      {comment.authorId === user.firebaseUserId && (
                        <Box fontSize="xs">
                          <Button
                            size="xs"
                            variant="ghost"
                            colorScheme="blue"
                            mr={2}
                            onClick={(e) => handleCommentEditClicked(e, comment)}
                          >
                            {editingComment === comment.id ? "Save" : "Edit"}
                          </Button>
                          <Button
                            size="xs"
                            variant="ghost"
                            colorScheme="red"
                            onClick={(e) => handleCommentDelete(e, comment)}
                            display={editingComment ? "none" : "inline-flex"}
                          >
                            Delete
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {/* Date and Time below every comment */}
                  <Box
                    fontSize="xs"
                    color="gray.500"
                    mt={1}
                    ml={12} // Align with the comment text
                    display="flex"
                    flexDir="row"
                    gap={2}
                  >
                    <Box>
                      {format(new Date(comment.createdAt), "MM/dd/yy")}
                    </Box>
                    <Box>
                      {format(new Date(comment.createdAt), "h:mm a")}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </VStack>
        )}
      </Box>
      {taskComments &&
        Array.isArray(taskComments) &&
        task &&
        taskComments.length < task.commentsCount && (
          <Box
            color="blue.500"
            fontSize="sm"
            fontWeight="600"
            marginTop={2}
            onClick={loadMoreTaskComments}
            _hover={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Load ({task.commentsCount - taskComments.length}) more comments...
          </Box>
        )}
      {expandedComment && (
        <ExpandedCommentView
          isOpen={!!expandedComment}
          onClose={handleCloseExpanded}
          comment={expandedComment}
        />
      )}
    </Box>
  );
};

export default AllTaskComments;

