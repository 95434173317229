import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Avatar,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import logo from "../../assets/logo.png";
import { LayoutState } from "../../Context/LayoutProvider";
import { AuthState } from "../../Context/AuthProvider";
import Organization from "../Miscellaneous/Organization/Organization";
import Notifications from "../NotificationComponents/Notifications";
import { useNavigate } from "react-router-dom";
import { IoMdNotifications } from "react-icons/io";
import { UserState } from "../../Context/UserProvider";

const Header = ({
  isOpen: isSideBarOpen,
  onOpen: openSidebar,
  onClose: closeSidebar,
}) => {
  const { isPhone } = LayoutState();
  const { user } = AuthState();
  const { organizations } = UserState();
  const [leaveSpaceAbove, setLeaveSpaceAbove] = useState("55");
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("purple.600", "purple.200");

  const handleResize = () => {
    const space =
      document.getElementsByClassName("header-container")[0]?.clientHeight;
    setLeaveSpaceAbove(space);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Box
        className="header-container"
        position="sticky"
        top={0}
        zIndex="sticky"
        // bg={bgColor}
        bgColor={"gray.50"}
        boxShadow="sm"
      >
        <Flex justifyContent="space-between" alignItems="center" py={2} px={4}>
          <Flex
            alignItems="center"
            gap={2}
            onClick={() => navigate("/dashboard")}
            _hover={{ cursor: "pointer" }}
          >
            {organizations && organizations?.length > 0 && (
              <IconButton
                icon={<HamburgerIcon />}
                variant="ghost"
                onClick={(e) => {
                  isSideBarOpen ? closeSidebar() : openSidebar();
                  e.stopPropagation();
                }}
                aria-label="Menu"
              />
            )}
            <Image src={logo} alt="PMBot" height="40px" />
            <Text
              fontWeight="bold"
              fontSize="xl"
              color={textColor}
              display={{ base: "none", md: "block" }}
            >
              PMBot
            </Text>
          </Flex>
          <Flex alignItems="center" gap={4}>
            {!isPhone && <Organization />}
            <Notifications />
            <Avatar
              src={user?.imageUrl}
              name={`${user?.firstName} ${user?.lastName}`}
              size="sm"
              onClick={() => {
                navigate("/profile");
              }}
              _hover={{
                cursor: "pointer", // Ensures the hand-pointer effect on hover
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Header;
